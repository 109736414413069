import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, Matches, validate } from "class-validator";

import { ErrorCode, ErrorMessage } from "@/constant";
import { AccountModel } from "@/models";
import { APLightbox } from "@/components";
import { Cookie } from "@/util";

export class LoginForm {
    @IsDefined() @IsNotEmpty({ message: "*必填" }) @Matches(/^(?=.*[a-z])(?=.*[A-Z])[A-Za-z].{6,}$/, { message: '*請輸入至少6位包含大小寫英文字母的密碼'}) newPwd = '';
    @IsDefined() @IsNotEmpty({ message: "*必填" }) confirmPwd = '';
    @IsDefined() verification = '';
}

@Component<ApTeacherLoginPasswordResetController>({
    components: {
        APLightbox
    }
})
export default class ApTeacherLoginPasswordResetController extends Vue {
    private doubleClick = false;
    private loginForm = new LoginForm();
    private verificationCode: { [key: string]: string } = {
        uid: "",
        imgSrc: ""
    };
    private errorMsgMap: { [key: string]: string } = {
        newPwd: '',
        confirmPwd: '',
        verification: '',
    };
    private lightbox: { showLB: boolean, errorMsg: string, confirmLink: string } = {
        showLB: false, 
        errorMsg:  '',
        confirmLink: '',
    };

    public created() {
        this.refreshVerificationCode(); //取得驗證碼圖片

        //驗證連結
        if (Cookie.getCookie('resetPwd') === null) {
            this.lightbox.errorMsg = '連結已失效';
            this.lightbox.showLB = true;
            this.lightbox.confirmLink = '/teacher/login';
        };
    }
    
    private async refreshVerificationCode() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const timestamp = Math.floor(Date.now() / 1000).toString();
            const random = Math.floor(Math.random() * 1000).toString();
            this.verificationCode.uid = timestamp + random;

            const codeItem = {
                uniqueID: this.verificationCode.uid
            };
            this.verificationCode.imgSrc = await AccountModel.refreshCodeImg(codeItem); //通過model call api回傳所需資料
            this.doubleClick = false;
        }
    }

    private async validateAndSubmit() {
        validate(this.loginForm, { skipMissingProperties: true }).then(
            async result => {
                this.errorMsgMap = { //先清空error message
                    newPwd: '',
                    confirmPwd: '',
                    verification: '',
                };

                if (result.length !== 0) {
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if(this.loginForm.confirmPwd != this.loginForm.newPwd){
                    this.errorMsgMap['confirmPwd'] = '*再次輸入密碼不符';
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const codeItem = {
                        code: this.loginForm.verification,
                        uniqueID: this.verificationCode.uid
                    };

                    // const verificationCodeErrNo = await AccountModel.verificationCodeCheck(codeItem); //呼叫api 驗證驗證碼正確性

                    // if (verificationCodeErrNo !== ErrorCode.Success) {
                    //     this.errorMsgMap['verification'] = ErrorMessage[verificationCodeErrNo];
                    //     this.doubleClick = false;
                    //     this.refreshVerificationCode();
                    //     return;
                    // }

                    const item = {
                        acc: this.$route.query.acc,
                        pwd: this.loginForm.newPwd,
                    };
                    const errNo = await AccountModel.teResetPwd(item);
                    this.lightbox.errorMsg = ErrorMessage[errNo];
                    this.lightbox.showLB = true;
                    if (errNo === ErrorCode.UserResetPwdSuccess || errNo === ErrorCode.UserResetPwdInvalid) {
                        this.lightbox.confirmLink = '/teacher/login';
                    } else {
                        this.refreshVerificationCode();
                    }
                    this.doubleClick = false;
                }
            }
        );
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}